export const constants = {

    api: {
        parceiro: 'ALLÔO MÓVEL',
    },

    texts: {
        nomeEmpresa: 'ALLÔO MÓVEL',
    },

    links: {
        linkPedirChip: 'https://alloo.com.br/cobertura/',
        linkApp: 'https://www.alloo.com.br/aplicativos/',
        linkWhatsAppSite: 'https://wa.me/message/ZESGXTGX36LEN1',
        linkWpp: 'https://wa.me/message/ZESGXTGX36LEN1',
        linkMapaCobertura: 'https://alloo.com.br/cobertura/',
        linkTenhaSuaOperadora: 'https://alloo.com.br/tenhasuaoperadora/',
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://alloo.com.br/privacidade/',
        linkInstagram: 'https://www.instagram.com/alloomovel/',
    },
}